import { styled, Button as MuiButton } from '@mui/material';


export const Button = styled(MuiButton)<any>(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: '8px',
  textTransform: 'uppercase',
  border: `1px solid ${theme.palette.primary.main}`,
  color: 'white',
  boxShadow: 'none',
  fontWeight: '700',
  fontSize: '1rem',
  padding: '9px 24px',

  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem',
    padding: '9px 12px',
  },

  '&:hover' : {
    background: theme.palette.primary.dark
  }
}));
