import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import DashboardLayout from "layouts/Dashboard";
import Dashboard from "pages/Dashboard";
import Profile from "pages/Profile";
import Register from "pages/Register";

import Login from "pages/Auth/Login";
import UpdatedDashboard from "pages/UpdatedDashboard";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsOfService from "pages/TermsOfService";

/*
  AppRoutes object contains path strings to use across application
*/
export const AppRoutes = {
  default: "/",
  profile: "/profile",
  register: "/register",
  login: "/login",
};

/*
  routes object returns <Routes> components with <Route> inside it
*/
const routes: RouteObject[] = [
  {
    path: AppRoutes.login,
    element: <Login />,
  },
  {
    element: (
    //   <RequireAuth>
        <DashboardLayout />
    //   </RequireAuth>
    ),
    children: [
      {
        path: AppRoutes.default,
        element: (
          <>
            <Dashboard />
          </>
        ),
      },
      {
        path: AppRoutes.profile,
        element: (
          <>
            <Profile />
          </>
        ),
      },
      {
        path: AppRoutes.register,
        element: (
          <>
            <Register />
          </>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={AppRoutes.default} />,
  },
];


export const updatedAppRoutes = {
  default: "/",
  privacyPolicy: "/privacy-policy",
  termsOfService: "/terms-of-service",
};

const updatedRoutes: RouteObject[] = [
  {
    element: (
    //   <RequireAuth>
        <DashboardLayout />
    //   </RequireAuth>
    ),
    children: [
      {
        path: updatedAppRoutes.default,
        element: (
          <>
            <UpdatedDashboard />
          </>
        ),
      },
      {
        path: updatedAppRoutes.privacyPolicy,
        element: (
          <>
            <PrivacyPolicy />
          </>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={updatedAppRoutes.default} />,
  },
];

export const AppRouter = () => {
  const renderRoutes = useRoutes(updatedRoutes);
  return renderRoutes;
};
