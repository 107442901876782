import { styled } from '@mui/material';

export const IntroHolder = styled('div')`
    overflow: hidden;
`;

export const Container = styled('div')`
    max-width: 1080px;
    margin: 0 auto 80px;
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
        margin: 20px auto 50px;
    }

    h2 {
        font-size: 36px;
        line-height: 40px;
        font-weight: 900;
        text-align: center;
        max-width: 870px;
        margin: 0 auto;

        span {
            color: #E3C050;
        }
        
    }

    @media (max-width: 1023px) {
        max-width: 460px;
        margin: 0 auto 65px;

        img {
            max-width: 100px;
            margin: 10px auto 24px;
        }

        h2 {
           font-size: 24px;
           line-height: 32px;
        }
    }

    @media (max-width: 767px) {
        max-width: 345px;
        margin: 0 auto 70px;

        h2 {
           font-size: 24px;
           line-height: 32px;
        }
    }
`;