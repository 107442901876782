import React from "react";
import { HowItWorksHolder, Container } from "components/HowItWorks/style";
import { Typography } from "@mui/material";
import HowItWorksImg from "assets/HowItWorks.svg";

const HowItWorks = () => {
  return (
    <HowItWorksHolder>
      <Container>
        <Typography variant="h2">How It Works</Typography>
        <img src={HowItWorksImg} alt="HowItWorksImg" />
      </Container>
    </HowItWorksHolder>
  );
};

export default HowItWorks;
