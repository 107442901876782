import React from "react";

const Login = () => {
  return (
    <>
      Login Layout
    </>
  );
};

export default Login;
