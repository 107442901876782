import React from "react";
import { IntroHolder, Container } from "components/Intro/style";
import Arrow from "assets/arrow.svg";
import { Typography } from "@mui/material";

const Intro = () => {
  return (
    <IntroHolder>
      <Container>
        <img src={Arrow} alt="Arrow" />
        <Typography variant="h2">
          <span>Access to capital</span> is an important part of Canada’s
          innovation strategy; however...
        </Typography>
      </Container>
    </IntroHolder>
  );
};

export default Intro;
