import { REQUEST_BUTTON_URL } from "config";
import React from "react";
import { Container, BottombarWrapper } from "./style";

const Bottombar = () => {
  return (
    <BottombarWrapper>
      <Container className="align-center">
        <div className="holder">
          <span className="copyright">Copyright 2024 SRED Plus Nexus Inc.</span>
          <ul>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
          </ul>
        </div>
      </Container>
    </BottombarWrapper>
  );
};

export default Bottombar;
