import Button from "elements/Button";
import { submit_hubspot_form } from "api/hubspot";
import { FormHolder, FormHeader } from "./style";
import { Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";

const MultiStepForm = () => {
  const [step, setStep] = useState(0);
  const {
    register,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  const disabled =
    step === 0 && errors.email
      ? true
      : step === 1 &&
        (errors.firstname || errors.lastname || errors.company || errors.phone)
      ? true
      : step === 2 &&
        (errors.r_d_related_contractors ||
          errors.r_d_related_contractors ||
          errors.r_d_related_materials)
      ? true
      : false;

  const onSubmit = (data: any) =>
    submit_hubspot_form(data).then(() => {
      toast.success("Success! Please schedule a meeting.", {
        onClose: () =>
          window.location.replace(
            "https://meetings.hubspot.com/daniel-barankin"
          ),
      });
    });

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <FormHolder>
      <FormHeader>
        <div className="header">{step + 1} of 3</div>
        <ul className="points">
          <li className={`active`}></li>
          <li className={step > 0 ? "active" : ""}></li>
          <li className={step > 0 && step === 2 ? "active" : ""}></li>
        </ul>
      </FormHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: step === 0 ? "block" : "none" }}>
          <strong className="title">Calculate your SRED Capital refund</strong>
          <label className="custom-label">
            <span className="label-title">Email</span>
            <input {...register("email", { required: true })} />
          </label>
        </div>
        <div style={{ display: step === 1 ? "block" : "none" }}>
          <strong className="title">You're nearly there</strong>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <label className="custom-label">
                <span className="label-title">First Name</span>
                <input {...register("firstname", { required: true })} />
              </label>
            </Grid>
            <Grid item xs={6}>
              <label className="custom-label">
                <span className="label-title">Last Name</span>
                <input {...register("lastname", { required: true })} />
              </label>
            </Grid>
          </Grid>
          <label className="custom-label">
            <span className="label-title">Company</span>
            <input {...register("company", { required: true })} />
          </label>
          <label className="custom-label">
            <span className="label-title">Phone</span>
            <input {...register("phone", { required: true })} />
          </label>
        </div>

        <div style={{ display: step === 2 ? "block" : "none" }}>
          <strong className="title">Last step</strong>
          <label className="custom-label">
            <span className="label-title">
              How much did you spend on R&D related salaries last year?
            </span>
            <input {...register("r_d_related_salaries", { required: true })} />
          </label>
          <label className="custom-label">
            <span className="label-title">
              How much did you spend on R&D related contractors last year?
            </span>
            <input
              {...register("r_d_related_contractors", { required: true })}
            />
          </label>
          <label className="custom-label">
            <span className="label-title">
              How much did you spend on R&D related materials last year?
            </span>
            <input {...register("r_d_related_materials", { required: true })} />
          </label>
        </div>

        <div className="btns">
          {step > 0 && step < 3 && (
            <Button onClick={() => setStep(step - 1)} type="button">
              <ArrowBackIcon />
              Prev
            </Button>
          )}
          {step < 2 ? (
            <Button
              disabled={disabled}
              onClick={() => setStep(step + 1)}
              type="button"
            >
              Next
              <ArrowForwardIcon />
            </Button>
          ) : (
            <Button disabled={disabled} type="submit">
              Submit
            </Button>
          )}
        </div>
        <Typography variant="body2" className="note">
          By submitting my personal data, I consent to SRED Capital collecting,
          processing, and storing my information in accordance with the SRED Capital
          Privacy Notice.
        </Typography>
      </form>
    </FormHolder>
  );
};

export default MultiStepForm;
