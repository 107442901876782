import { Box, Typography } from "@mui/material";
import Bottombar from "pages/UpdatedDashboard/Bottombar";
import ContactInfo from "pages/UpdatedDashboard/ContactInfo";
import Header from "pages/UpdatedDashboard/Header";
import { Container, Wrapper } from "pages/UpdatedDashboard/style";
import Topbar from "pages/UpdatedDashboard/Topbar";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <Topbar />
      <Header />
      <Container sx={{
          background: 'white',
          borderRadius: "5px",
          marginTop: "30px",
          "ul" : {
            listStyle: "outside",
            margin: "10px",
            padding: "10px 10px 10px 20px",
            "> li": { padding: "0 0 0 10px" } 
          },
          "ol" : {
            margin: "10px",
            padding: "10px 10px 10px 20px",
            "> li": { padding: "0 0 20px 10px" } 
          }
        }}>
        <Box sx={{ padding: "20px" }}>
          <Typography variant="h1">Privacy Policy</Typography><br />
          <Typography variant="h5" sx={{ fontWeight: "400" }}><strong>Last Updated:</strong> August 8th,2024</Typography><br />
          <Typography variant="body2"><strong>SREDCapital</strong> ("we," "our," or "us") is committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website https://sredplus.com/ and use our services. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.</Typography><br />
          <ol>
            <li>
              <Typography variant="h4">Information We Collect</Typography><br />
              <Typography variant="body2">We may collect and process the following data about you:</Typography>
              <ul>
                <li>
                  <Typography variant="body2"><strong>Personal Identification Information:</strong> Name, email address, phone number, and other contact details that you provide by filling out forms on our website or by corresponding with us by phone, email, or otherwise.</Typography>
                </li>
                <li>
                  <Typography variant="body2"><strong>Technical Data:</strong> Includes your IP address, browser type and version, time zone setting, browser plug-in types and versions, operating system, and platform.</Typography>
                </li>
                <li>
                  <Typography variant="body2"><strong>Usage Data:</strong> Information about how you use our website, products, and services.</Typography>
                </li>
                <li>
                  <Typography variant="body2"><strong>Marketing and Communications Data:</strong> Your preferences in receiving marketing from us and your communication preferences.</Typography>
                </li>
              </ul>
            </li>
            <li>
              <Typography variant="h4">How We Use Your Information</Typography><br />
              <Typography variant="body2">We use the information we collect in the following ways:</Typography>
              <ul>
                <li><Typography variant="body2">To provide, operate, and maintain our website and services.</Typography></li>
                <li><Typography variant="body2">To improve, personalize, and expand our website and services.</Typography></li>
                <li><Typography variant="body2">To communicate with you, including responding to your inquiries and providing you with updates and other information.</Typography></li>
                <li><Typography variant="body2">To process transactions and manage your account.</Typography></li>
                <li><Typography variant="body2">To send you marketing and promotional materials, including advertisements on Google and Facebook, in line with your preferences.</Typography></li>
                <li><Typography variant="body2">To comply with legal obligations and enforce our agreements.</Typography></li>
              </ul>            
            </li>
            <li>
              <Typography variant="h4">Sharing Your Information</Typography><br />
              <Typography variant="body2">We may share your personal information with third parties under the following circumstances:</Typography>
              <ul>
                <li>
                  <Typography variant="body2"><strong>Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</Typography>
                </li>
                <li>
                  <Typography variant="body2"><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</Typography>
                </li>
                <li>
                  <Typography variant="body2"><strong>Business Transfers:</strong> If we are involved in a merger, acquisition, or asset sale, your information may be transferred as part of that transaction.</Typography>
                </li>
              </ul>
            </li>
            <li>
              <Typography variant="h4">Cookies and Tracking Technologies</Typography><br />
              <Typography variant="body2">We use cookies and similar tracking technologies to track activity on our website and store certain information. Cookies are files with a small amount of data that are placed on your computer or device. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our website.</Typography><br />
            </li>
            <li>
              <Typography variant="h4">Security of Your Information</Typography><br />
              <Typography variant="body2">We use administrative, technical, and physical security measures to protect your personal information. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.</Typography><br />
            </li>
            <li>
              <Typography variant="h4">Your Data Protection Rights</Typography>
              <ul>
                <li>
                  <Typography variant="body2"><strong>The right to object to processing –</strong> You have the right to object to our processing of your personal data, under certain conditions.</Typography>
                </li>
                <li>
                  <Typography variant="body2"><strong>The right to data portability –</strong> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</Typography>
                </li>
              </ul>
              <Typography variant="body2">If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our contact information provided below.</Typography>
            </li>
            <li>
              <Typography variant="h4">Advertising and Analytics Services Provided by Others</Typography><br />
              <Typography variant="body2">We may allow others to provide analytics services and serve advertisements on our behalf across the internet and in mobile applications. These entities may use cookies, web beacons, device identifiers, and other technologies to collect information about your use of the website and other websites and applications, including your IP address, web browser, pages viewed, time spent on pages, links clicked, and conversion information.</Typography><br />
              <Typography variant="h5">Google Ads and Facebook Ads</Typography>
              <ul>
                <li>
                <Typography variant="body2"><strong>Google Ads: </strong>We use Google Ads to serve ads to visitors to our website on other platforms. Google uses cookies and tracking technologies to deliver ads that are relevant to you based on your past visits to our website and your online behavior. You can opt out of personalized advertising by visiting Google’s Ads Settings.</Typography>
                </li>
                <li>
                  <Typography variant="body2"><strong>Facebook Ads: </strong>We use Facebook's advertising services to display ads to visitors to our website when they are on Facebook or a digital platform powered by Facebook. Facebook may use cookies, web beacons, and other storage technologies to collect or receive information from our website and elsewhere on the internet and use that information to provide measurement services and target ads. You can opt-out of Facebook's personalized ads through Facebook’s Ad Preferences.</Typography>
                </li>
              </ul>
            </li>
            <li>
              <Typography variant="h4">Your Advertising Choices</Typography><br />
              <Typography variant="body2">Opt-Out of Cookies: You can set your browser to refuse all or some browser cookies or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of our website may become inaccessible or not function properly.</Typography><br/>
              <Typography variant="body2">Google Ads Settings: You can opt out of Google’s personalized advertising by visiting the Google Ads Settings. You can also install the Google Analytics Opt-Out Browser Add-on to prevent your data from being used by Google Analytics.</Typography><br/>
              <Typography variant="body2">Facebook Ad Preferences: To control how Facebook uses your information to show you ads, you can adjust your ad settings through Facebook's Ad Preferences.</Typography><br/>
              <Typography variant="body2">Digital Advertising Alliance: You can opt out of interest-based advertising from companies participating in the Digital Advertising Alliance (DAA) by visiting the DAA’s opt-out page.</Typography><br/>
              <Typography variant="body2">Network Advertising Initiative: You can also opt out of interest-based advertising from companies that participate in the Network Advertising Initiative by visiting the NAI’s opt-out page.</Typography><br/>
              <Typography variant="body2">Mobile Advertising: To opt out of mobile device tracking for interest-based advertising, you can adjust the settings on your mobile device. For example, you can change the advertising identifier on Apple devices through the "Privacy" section in your settings.</Typography><br/>
            </li>
            <li>
              <Typography variant="h4">Changes to This Privacy Policy</Typography><br />
              <Typography variant="body2">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</Typography>
            </li>
          </ol>
        </Box><br /><br />
      </Container>
      <ContactInfo />
      <Bottombar />
    </Wrapper>
  );
};

export default PrivacyPolicy;
