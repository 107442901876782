import { Typography } from "@mui/material";
import React from "react";
import { Container, HeadingTwo, SectionWrapper } from "./style";

const AboutUs = () => {
  return (
    <SectionWrapper>
      <Container className="align-center">
        <HeadingTwo variant="h2">Our Canadian Mission</HeadingTwo>
        <Typography variant="body1" sx={{fontSize: "16px", lineHeight: "1.5"}}>For over 20 years, SRED Capital has been committed to assisting Canadian companies in recovering capital through SR&ED claims. As leaders in SR&ED expertise, we ensure our clients achieve the maximum possible cash refunds. Over our history, we have secured tens of millions of dollars in claims for Scientific Research and Experimental Development.</Typography>
      </Container>
    </SectionWrapper>
  );
};

export default AboutUs;
