import { REQUEST_BUTTON_URL } from "config";
import React from "react";
import { PageHeader, Logo } from "./style";
import SiteLogo from "assets/light_theme/logo.svg";
import Button from "elements/Button";

const Header = () => {
  return (
    <PageHeader>
      <Logo>
        <img src={SiteLogo} alt="SRED" />
      </Logo>
      <Button color="primary" onClick={() => window.location.href = REQUEST_BUTTON_URL}>REQUEST A CONSULTATION</Button>
    </PageHeader>
  );
};

export default Header;
