import { styled } from '@mui/material';

export const FooterContentHolder = styled('div')`
    overflow: hidden;
`;

export const Container = styled('div')`
    max-width: 1080px;
    margin: 0 auto 100px;

    @media (max-width: 1023px) {
        max-width: 605px;
        margin: 0 auto 90px;
    }

    @media (max-width: 767px) {
        max-width: 345px;
        margin: 0 auto 140px;
    }

    h2 {
        font-size: 36px;
        line-height: 45px;
        font-weight: 900;
        text-align: center;
        max-width: 950px;
        margin: 0 auto 24px;

        span {
            display: block;
            color: #E3C050;
        }

        @media (max-width: 1023px) {
            font-size: 24px;
            line-height: 30px;
            margin: 0 auto 40px;
        }
    }

    .sub-head {
        color: #8C8E95;
        font-size: 22px;
        line-height: 32px;
        max-width: 895px;
        margin: 0 auto 45px;
        text-align: center;

        @media (max-width: 1023px) {
            font-size: 16px;
            line-height: 22px;
            max-width: 560px;
        }
    }
`;


export const LogoHolder = styled('div')`
    max-width: 200px;
    margin: 0 auto;
`;

export const Box = styled('div')`
    border: 2px solid #DBB163;
    background: linear-gradient(180deg, #000000 0%, rgba(54, 54, 54, 0) 100%);
    border-radius: 8px;
    padding: 30px 20px;
    max-width: 350px;
    margin: 0 auto 100px;

    @media (max-width: 1023px) {
        margin: 0 auto 90px;
    }

    @media (max-width: 767px) {
        margin: 0 auto 70px;
    }

    .chips {
        display: flex;
        justify-content: space-between;
        margin: 0 0 16px;
    }

    .head {
        color: #E9E9E9;
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        margin: 0 0 16px;
    }

    .box-head {
        overflow: hidden;
        border-bottom: 1px solid #1f1f1f;
        padding: 0 0 10px;
        text-align: left;
        margin: 0 0 16px;

        h3 {
            font-size: 40px;
            line-height: 48px;
            font-weight: 900;
            margin: 0;
        }

        .sub-heading {
            color: #8C8E95;
            font-size: 16px;
            line-height: 20px;
            margin: 0 auto 10px;
        }
    }

    .checks {
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 16px;
        line-height: 22px;

        li {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 25px 0 0;

            &:first-child {
                margin-top: 0;
            }

            svg {
                margin: 0 15px 0 0;
                fill: #E3C050;
            }
        }
    }

`;