import { Box } from "@mui/system";
import Dashboard from "assets/bg-dashboard.jpg";
import MultiStepForm from "components/MultiStepForm";

const Register = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        position: "relative",
        "&:after": {
          background: "rgba(19, 19, 19, 0.9)",
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          content: '""',
        },
        img: {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      }}
    >
      <img src={Dashboard} alt="Dashboard BG" className="bg-dashboard" />
      <MultiStepForm />
    </Box>
  );
};

export default Register;
