import { styled } from '@mui/material';
import AppBar from "@mui/material/AppBar";

export const FormHolder = styled('div')`
    z-index: 99;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background: #1F1F1F;
    padding: 44px;
    border-radius: 6px;

    .title {
      display: block;
      color: #E9E9E9;
      font-size: 19px;
      line-height: 25px;
      font-weight: 700;
      margin: 0 0 24px;
    }

    .note {
      color: #575757;
      font-size: 12px;
      line-height: 16px;
      margin: 0;
      text-align: center;
    }


     @media (max-width: 767px) {
        width: 373px;
        padding: 24px;
     }

     @media (max-width: 390px) {
        width: 300px;
        padding: 15px 10px;
     }

     .go2392553372 {
      pointer-events: none;
     }

     .custom-label {
      display: block;
      border: 1px solid #373737;
      border-radius: 6px;
      color: white;
      height: 48px;
      position: relative;
      background: #131313;
      margin: 0 0 14px;

      .label-title {
        display: block;
        position: absolute;
        left: 16px;
        top: 8px;
        font-size: 12px;
        line-height: 1;
        color: #8C8E95;
      }

      input {
        background: transparent;
        width: 100%;
        height: 48px;
        padding: 24px 16px 8px;
        outline: none;
        border: none;
        color: white;
        font-size: 16px;
        line-height: 16px;
      }
     }

     .btns {
      padding: 10px 0 24px;
      display: flex;


      button {
        flex: 1;
        color: white;
        background: #000;
        cursor: pointer;
        margin: 0 3px;

        svg {
          width: 16px;
          height: 16px;
          margin: 0 5px;
        }

        &.Mui-disabled {
          opacity: 0.5;
        }

      }
     }
`;


export const FormHeader = styled('div')`
  overflow: hidden;
  text-align: center;

  .header {
    color: #c0c0c0;
    font-size: 16px;
    line-height: 1;
    margin: 0 0 24px;
  }

  .points {
    padding: 0;
    margin: 0 0 30px;
    list-style: none;
    display: flex;
    justify-content: center;

    li {
      height: 5px;
      width: 40px;
      border-radius: 100px;
      background: #373737;
      margin: 0 4px 4px;

      &.active {
        background: #E3C050;
      }
    }
  }
`;