import React from "react";
import {
  ProductInfoHolder,
  Container,
  Block,
  Icons,
} from "components/ProductInfo/style";
import { Typography } from "@mui/material";
import Map from "assets/map.jpg";
import Icon1 from "assets/icon-1.svg";
import Icon2 from "assets/icon-2.svg";
import Icon3 from "assets/icon-3.svg";
import Icon4 from "assets/icon-4.svg";
import Icon5 from "assets/icon-5.svg";
import Icon6 from "assets/icon-6.svg";
import Icon7 from "assets/icon-7.svg";
import Icon8 from "assets/icon-8.svg";
import Icon9 from "assets/icon-9.svg";
import Icon10 from "assets/icon-10.svg";
import Icon11 from "assets/icon-11.svg";

const ProductInfo = () => {
  return (
    <ProductInfoHolder>
      <Container>
        <Typography variant="h2">
          SRED Capital is how the best Canadian entrepreneurs get near-instant
          access to non-dilutive capital with less stress and more success.
        </Typography>
        <img src={Map} className="map" alt="map" />
        <Block>
          <span>
            $500M RECOVERED <br />
            PROVEN PROCESS
          </span>
          <span className="title">Access Capital</span>
          <span>
            99% SUCCESS RATE <br />
            30 YEARS EXPERIENCE
          </span>
        </Block>
        <Icons>
          <li>
            <img src={Icon11} alt="Icon" />
          </li>
          <li>
            <img src={Icon1} alt="Icon" />
          </li>
          <li>
            <img src={Icon3} alt="Icon" />
          </li>
          <li>
            <img src={Icon2} alt="Icon" />
          </li>
          <li>
            <img src={Icon4} alt="Icon" />
          </li>
          <li>
            <img src={Icon5} alt="Icon" />
          </li>
          <li>
            <img src={Icon6} alt="Icon" />
          </li>
          <li>
            <img src={Icon7} alt="Icon" />
          </li>
          <li>
            <img src={Icon8} alt="Icon" />
          </li>
          <li>
            <img src={Icon9} alt="Icon" />
          </li>
          <li>
            <img src={Icon10} alt="Icon" />
          </li>
        </Icons>
      </Container>
    </ProductInfoHolder>
  );
};

export default ProductInfo;
