import axios from 'axios'

const fields = ['email', 'firstname', 'lastname', 'company', 'phone',
	'r_d_related_salaries', 'r_d_related_contractors', 'r_d_related_materials']

const field = fields.map((field) => ({ name: field, value: field }))

export const submit_hubspot_form = (data: any) => {
	const portalId = '24145163'; // example portal ID (not real)
	const formGuid = '7b0c809d-007a-4d24-a77c-36d8b1128d81'; // example form GUID (not real)
	const config = { // important!
		headers: {
			'Content-Type': 'application/json',
		},
	}
	return axios
		.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
			{
				portalId,
				formGuid,
				fields: Object.entries(data).map((field) => ({ name: field[0], value: field[1]}))
				,
			},
			config
		).then(() => true)
}
