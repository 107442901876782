import React from "react";
import { SliderHolder, Column, Container } from "components/Slider/style";
import AlertSign from "assets/alert-triangle.svg";
import { Typography } from "@mui/material";

const Slider = () => {
  return (
    <SliderHolder>
      <Container>
        <Column>
          <img src={AlertSign} alt="Alert" />
          <Typography variant="h2">
            <span>Complex tax laws</span> hinder R&D investment
          </Typography>
        </Column>
        <Column>
          <img src={AlertSign} alt="Alert" />
          <Typography variant="h2">
            <span>Limited R&D resources</span>
            restrict growth potential
          </Typography>
        </Column>
        <Column>
          <img src={AlertSign} alt="Alert" />
          <Typography variant="h2">
            <span>Fear of audits</span> deter SRED Capital claims
          </Typography>
        </Column>
      </Container>
    </SliderHolder>
  );
};

export default Slider;
