import { Typography, Button } from "@mui/material";
import React from "react";
import { Container, BoxHolder, CustomBox, ComparisonBlock, Block, HeadingTwo, SectionWrapper, TagsList, TagListItem, SeperatorImage, BoxCol, BtnWrapper, BlockHolder } from "./style";
import Divider from "assets/light_theme/divider.svg";
import SiteLogo from "assets/light_theme/logo.svg";
import { REQUEST_BUTTON_URL } from "config";
import CustomButton from "elements/Button";

const HowItWorks = () => {
  return (
    <SectionWrapper>
      <Container className="align-center">
        <HeadingTwo variant="h2">How it works</HeadingTwo>
        <ComparisonBlock>
          <Block>
            <BlockHolder>
              <Typography variant="h5">Financial Information:</Typography>
              <TagsList>
                <TagListItem>
                  <Button color="secondary" className="tag-button" variant="contained">T2 Corporate Taxes</Button>
                </TagListItem>
                <TagListItem>
                  <Button color="secondary" className="tag-button" variant="contained">T4 Canadian Salaries</Button>
                </TagListItem>
                <TagListItem>
                  <Button color="secondary" className="tag-button" variant="contained">Invoices from Canadian Business Partners</Button>
                </TagListItem>
              </TagsList>
            </BlockHolder>
            <BlockHolder>
              <Typography variant="h5">Technical Documentation:</Typography>
              <TagsList>
                <TagListItem>
                  <Button color="secondary" className="tag-button" variant="contained">Project Plans</Button>
                </TagListItem>
                <TagListItem>
                  <Button color="secondary" className="tag-button" variant="contained">Timesheets</Button>
                </TagListItem>
                <TagListItem>
                  <Button color="secondary" className="tag-button" variant="contained">Supporting Documentation</Button>
                </TagListItem>
              </TagsList>
            </BlockHolder>
          </Block>
          <Block className="has-seps">
            
            <SeperatorImage className="transform">
              <img src={Divider} />
            </SeperatorImage>
            <BlockHolder>
              <Typography variant="h5">10 hours working with:</Typography>
              <SeperatorImage className="hidden-small">
                <img src={SiteLogo} alt="SRED" width={'207'} height={'60'} />
              </SeperatorImage>
            </BlockHolder>
            <SeperatorImage className="transform">
              <img src={Divider} />
            </SeperatorImage>
          </Block>
          <Block>
            <BlockHolder>
              <Typography variant="h5">2-3 months for the CRA to process:</Typography>
              <TagsList>
                <TagListItem>
                  <Button color="secondary" className="tag-button" variant="contained">Your SRED Capital Reimbursement</Button>
                </TagListItem>
              </TagsList>
            </BlockHolder>
          </Block>
        </ComparisonBlock>
        <BoxHolder>
          <BoxCol>
            <CustomBox>
              <div className="count">1</div>
              <Typography variant="h5">Initial Consultation</Typography>
              <Typography variant="body1">Let’s discuss your businesses eligibility & estimate the value of expected SRED Capital</Typography>
            </CustomBox>
          </BoxCol>
          <BoxCol>
            <CustomBox>
              <div className="count">2</div>
              <Typography variant="h5">Our Process</Typography>
              <Typography variant="body1">Review existing documentation Refine to exceed CRA standards Receive SRED CAPITAL</Typography>
            </CustomBox>
          </BoxCol>
          <BoxCol>
            <CustomBox>
              <div className="count">3</div>
              <Typography variant="h5">Preparation</Typography>
              <Typography variant="body1">In just 10 hours of your time, we’ll prepare your SR&ED submission for CRA approval</Typography>
            </CustomBox>
          </BoxCol>
        </BoxHolder>
        <BtnWrapper>
          <CustomButton color="primary" onClick={() => window.location.href = REQUEST_BUTTON_URL}>GET STARTED</CustomButton>
        </BtnWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default HowItWorks;
