import React from "react";

const Profile = () => {
  return (
    <>
      Profile Page
    </>
  );
};

export default Profile;
