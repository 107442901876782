import { styled, Button as MuiButton, Box, Typography, List, ListItem } from '@mui/material';

export const Wrapper = styled(Box)`
  width: 100%;
  overflow: hidden;
`;

export const Container = styled(Box)`
  max-width: 1130px;
  padding: 0 10px;
  margin: 0 auto;

  &.align-center {
    text-align: center;
    align-items: center;
  }
`;

export const TopbarWrapper = styled(Box)`
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px;
  line-height: 1.35;
  font-weight: bold;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  
  @media (min-width: 768px){
    font-size: 14px;
    line-height: 18px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  
  .link {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    font-weight: normal;
    text-decoration: underline;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
    }
  }

  .breakline{
    
    @media (max-width: 767px){
      display: block;
      margin-top: 4px;
    }
  }
`;

export const BottombarWrapper = styled(Box)`
  padding: 5px 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};

  .holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    @media (min-width: 768px){
      flex-direction: row;
    }
  }
  

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 10px;
  }

  a {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    font-weight: normal;
    text-decoration: none;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px;
    font-size: 14px;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const PageHeader = styled(Box)`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;

  @media (min-width: 768px){
    padding: 15px 30px;
  }

  button{

    @media (max-width: 767px){
      font-size: 13px;
      padding: 7px 12px;
      white-space: nowrap;
      min-width: 1px;
    }

    @media (max-width: 400px){
      font-size: 12px;
      padding: 7px 10px;
    }
  }
`;

export const Logo = styled(Box)`
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;

  @media (max-width: 767px){
    img {
      width: 120px;
      vertical-align: top;
    }
  }

  @media (max-width: 400px){
    img {
      width: 100px;
      vertical-align: top;
    }
  }
`;

export const IntroWrapper = styled(Box)`
  padding-top: 48px;
  padding-bottom: 31px;
  text-align: center;

  @media (min-width: 992px){
    padding-top: 62px;
    padding-bottom: 62px;
  }
  @media (min-width: 1200px){
    padding-top: 125px;
  }
`;

export const HeadHeading = styled(Typography)`
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 1.35;

  @media (min-width: 992px){
    font-size: 38px;
  }
  @media (min-width: 1200px){
    font-size: 48px;
  }

  .text-highlight{
    display: block;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const TickList = styled(List)`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
`;

export const TickListItem = styled(ListItem)`
  padding: 0;
  font-size: 16px;
  justify-content: center;
  gap: 8px;
  font-weight: 500;

  @media (min-width: 768px){
    width: auto;
    font-size: 18px;
    gap: 16px;
  }
`;

export const IconWrap = styled(`i`)`
  line-height: 0;
`;

export const BtnWrapper = styled(Box)`
  margin-top: 48px;
  text-align: center;

  @media (min-width: 992px){
    margin-top: 45px;
  }
`;

export const SectionWrapper = styled(Box)`
  padding-top: 31px;
  padding-bottom: 31px;

  @media (min-width: 1200px){
    padding-top: 62px;
    padding-bottom: 62px;
  }
`;

export const BoxHolder = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  justify-content: center;
  margin-left: -12px;
  margin-right: -12px;

  @media (min-width: 768px){
    row-gap: 24px;
  }
`;

export const CustomBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 36px;
  text-align: center;
  background: ${({ theme }) => theme.palette.primary.contrastText};
  box-shadow: 0 4px 15px rgba(231,233,240, 0.25);

  p{
    font-size: 16px;
    line-height: 1.5;
  }

  h3{
    font-weight: 700;
    font-size: 24px;
    line-height: 1.35;
    color: ${({ theme }) => theme.palette.text.secondary};

    @media (min-width: 768px){
      font-size: 30px;
    }
  }

  h5{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .count{
    width: 40px;
    height: 40px;
    border-radius: 11px;
    margin: 0 auto 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,128,255, 0.1);
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const BoxTopWrap = styled(Box)`
  flex-grow: 1;
`;

export const BoxBottomWrap = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.palette.secondary.main};
  margin-top: 16px;
  padding-top: 16px;
`;

export const BoxCol = styled(Box)`
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  width: 100%;

  @media (min-width: 768px){
    width: 50%;
  }
  @media (min-width: 992px){
    width: 33.333%;
  }
`;

export const HeadingTwo = styled(Typography)`
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 48px;
  text-align: center;

  @media (min-width: 992px){
    font-size: 36px;
  }
`;

export const QuotesHolder = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  margin-left: -12px;
  margin-right: -12px;
`;

export const QuoteBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 36px;
  text-align: left;
  background: ${({ theme }) => theme.palette.primary.contrastText};
  box-shadow: 0 4px 15px rgba(231,233,240, 0.25);

  h4,
  h5{
    font-size: 18px;
    font-weight: 700;
  }

  h5{

    @media (max-width: 767px){
      font-size: 16px;
    }
  }

  h5,
  p{
    margin-top: 16px;
  }

  .designation{
    display: block;
    margin-top: 5px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 16px;
  }

  p{
    font-size: 16px;
    line-height: 1.5;
  }
`;

export const QuoteCol = styled(Box)`
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  min-width: 100%;
  flex-grow: 1;
  flex-basis: 0;

  @media (min-width: 768px){
    min-width: 50%;
  }
`;

export const ComparisonBlock = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% + 24px);
  margin: 0 -12px 48px;
  flex-direction: column;

  @media (min-width: 768px){
    flex-direction: row;
  }
`;

export const Block = styled(Box)`
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;

  @media (min-width: 768px){
    gap: 48px;
  }

  &.has-seps{
    flex-direction: column;

    @media (min-width: 768px){
      flex-direction: row;
    }

    @media (max-width: 767px){
      gap: 0;
    }
  }
`;

export const BlockHolder = styled(Box)`
  display: flex;
  flex-direction: column;

  h5 {
    margin-bottom: 10px;
  }
`;

export const TagsList = styled(List)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 24px;
  justify-content: center;
  width: 100%;

  @media (min-width: 768px){
    max-width: 200px;
  }
  @media (min-width: 992px){
    max-width: 400px;
  }
`;

export const TagListItem = styled(ListItem)`
  padding: 0;
  width: auto;

  .tag-button{
    border-radius: 6px;
    font-size: 15px;
    font-weight: 400;
    box-shadow: none;
    text-transform: none;
    padding: 14px 16px;
    color: ${({ theme }) => theme.palette.text.secondary};

    @media (min-width: 992px){
      border-radius: 11px;
      font-size: 16px;
    }
  }
`;

export const SeperatorImage = styled(`span`)`

  &.transform{

    @media (max-width: 767px){
      transform: rotate(90deg);
    }
  }

  &.hidden-small{

    @media (max-width: 767px){
      /* margin: -40px 0 -60px; */
    }

    img{

      @media (max-width: 767px){
        width: 207px;
      }
    }
  }
`;

export const ContactList = styled(List)`
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  justify-content: center;
  margin-left: -12px;
  margin-right: -12px;
`;

export const ContactListItem = styled(ListItem)`
  padding: 0 12px;
  width: 100%;
  display: block;
  text-align: center;

  @media (min-width: 768px){
    width: 33.333%;
  }

  h5{
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
`;

export const BottomNoteText = styled(Typography)`
  font-size: 16px;
  line-height: 1.5;
  margin-top: 31px;

  @media (min-width: 1200px){
    margin-top: 62px;
  }
  @media (min-width: 1200px){
    margin-top: 125px;
  }
`;