import React from "react";
import AboutUs from "./AboutUs";
import Bottombar from "./Bottombar";
import ContactInfo from "./ContactInfo";
import CreditInfo from "./CreditInfo";
import Header from "./Header";
import HowItWorks from "./HowItWorks";
import Intro from "./Intro";
import Packages from "./Packages";
import { Wrapper } from "./style";
import Topbar from "./Topbar";

const UpdatedDashboard = () => {
  return (
    <Wrapper>
      <Topbar />
      <Header />
      <Intro />
      {/* <Packages /> */}
      <CreditInfo />
      <HowItWorks />
      <AboutUs />
      <ContactInfo />
      <Bottombar />
    </Wrapper>
  );
};

export default UpdatedDashboard;
