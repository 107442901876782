import React from "react";
import Banner from "components/Banner";
import Comparison from "components/Comparison";
import FooterContent from "components/FooterContent";
import HowItWorks from "components/HowItWorks";
import Intro from "components/Intro";
import ProductInfo from "components/ProductInfo";
import Slider from "components/Slider";
import Header from "components/Header";

const Dashboard = () => {
  return (
    <>
      <Header />
      <Banner />
      <Intro />
      <Slider />
      <Comparison />
      <ProductInfo />
      <HowItWorks />
      <FooterContent />
    </>
  );
};

export default Dashboard;
