import { styled } from '@mui/material';

export const ProductInfoHolder = styled('div')`
    overflow: hidden;
`;

export const Container = styled('div')`
    max-width: 1080px;
    margin: 0 auto 99px;
    border-bottom:1px solid #2B2B2B;

    @media (max-width: 1023px) {
        max-width: 605px;
        margin: 0 auto 70px;
    }

    @media (max-width: 767px) {
        max-width: 345px;
        margin: 0 auto 60px;
    }

    img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;

        &.map {
          margin: 0 auto 24px;
        }
    }

    h2 {
        font-size: 36px;
        line-height: 45px;
        font-weight: 900;
        text-align: center;
        margin: 0 auto 24px;

        span {
            display: block;
            color: #E3C050;
        }

        @media (max-width: 1023px) {
            font-size: 24px;
            line-height: 30px;
            margin: 0 auto 20px;
        }
    }
`;

export const Block = styled('div')`
    display: flex;
    background: #1f1f1f;
    padding: 40px 50px;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin: 0 0 24px;

    @media (max-width: 1023px) {
        flex-direction: column;
        font-size: 16px;
        line-height: 22px;
        margin: 0 0 24px;
        padding: 30px 20px;
    }

    span {
        width: 25%;

        @media (max-width: 1023px) {
            width: 100%;
        }
    }

    .title {
        font-size: 48px;
        line-height: 56px;
        font-weight: 900;
        width: 50%;
        font-family: 'Playfair Display, serif';

        @media (max-width: 1023px) {
            width: 100%;
            font-size: 36px;
            line-height: 56px;
            padding: 24px 0;
        }
    }
`;

export const Icons = styled('ul')`
    padding: 0;
    margin: 0 auto 90px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 900px;
    justify-content: center;

    @media (max-width: 1023px) {
        margin: 0 auto 70px;
    }

    @media (max-width: 767px) {
        margin: 0 auto 60px;
    }

    li {
        margin: 0 5px 10px;

        @media (max-width: 1023px) {
            margin: 0 3px 6px;
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;

            @media (max-width: 1023px) {
                max-width: 81px;
            }
        }
    }

`;