import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

export const themeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#131313",
      light: "#373737",
      dark: "#000",
      contrastText: "#e9e9e9",
    },
    secondary: {
      main: "#1f1f1f",
    },
    success: {
      main: "#E3C050",
    },
    background: {
      paper: "#1f1f1f",
      default: "#131313",
    },
    text: {
      secondary: "#e9e9e9",
      primary: "#fff",
    },
  },
  typography: {
    fontSize: 16,
    htmlFontSize: 16,
    h1: {
      fontSize: 70,
      fontWeight: 700,
      fontFamily: '"Playfair Display", "serif"',
    },
    h2: {
      fontSize: 24,
      fontWeight: 400,
      fontFamily: '"Playfair Display", "serif"',
    },
    h3: {
      fontSize: 18,
      fontFamily: '"Playfair Display", "serif"',
    },
    h4: {
      fontFamily: '"Playfair Display", "serif"',
      fontSize: 16,
    },
    button: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 16,
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
        },
      },
    },
  },
};

export const lightThemeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#0080FF",
      light: "#373737",
      dark: "#005cb5",
      contrastText: "#fff",
    },
    secondary: {
      main: "#E7E9F0",
    },
    success: {
      main: "#E3C050",
    },
    background: {
      paper: "#1f1f1f",
      default: "#F5F6FA",
    },
    text: {
      secondary: "#171717",
      primary: "#515765",
    },
  },
  typography: {
    fontSize: 16,
    htmlFontSize: 16,
    h1: {
      fontSize: 48,
      fontWeight: 700,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      color: '#171717',
    },
    h2: {
      fontSize: 36,
      fontWeight: 700,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      color: '#171717',
    },
    h3: {
      fontSize: 30,
      fontWeight: 700,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      color: '#171717',
    },
    h4: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      color: '#171717',
      fontSize: 18,
      fontWeight: 700,
    },
    h5: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      color: '#171717',
      fontSize: 16,
      fontWeight: 700,
    },
    button: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 16,
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
        },
      },
    },
  },
};

const darkTheme = createTheme(themeOptions);
const lightTheme = createTheme(lightThemeOptions);

const DashboardLayout: FC<any> = (props) => {
  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Box component="main" sx={{ flexGrow: 1 }}>
            <Outlet {...props} />
          </Box>
        </Box>
      </ThemeProvider>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default DashboardLayout;
