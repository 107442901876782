import { styled, Chip as MuiChip } from '@mui/material';

export const Chip = styled(MuiChip)`
  background: #373737;
  height: 32px;
  border: none;
  border-radius: 6px;
  color: #E3C050;
  padding: 0 12px;

  & .MuiChip-label{
    padding: 6px 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &.lg {
    & .MuiChip-label{
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.success {
    background: rgba(37, 61, 49, 0.5);
    color: #83BF99;

    svg {
      width: 16px;
      margin: 0 5px 0 0;
      fill: #83BF99;
    }
  }

  &.bg-dark{
  }

  &.bg-light-yellow{

  }

  &.bg-white{

  }

  &.tag-theme-blue {

    & .MuiChip-label {
      text-transform: none;
      letter-spacing: 0;
    }
  }

  &.filter-chip{
    height: auto;
    border-radius: 50px;
    margin: 5px 0;

    &:not(:last-child) {
      margin-right: 10px
    }

    & .MuiChip-label{
      padding: 5px 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: none;
      letter-spacing: 0;
    }
  }
}
`;
