import { REQUEST_BUTTON_URL } from "config";
import React from "react";
import { Container, QuotesHolder, QuoteBox, HeadingTwo, SectionWrapper, QuoteCol, BtnWrapper } from "./style";
import SiteLogo from "assets/light_theme/logo.svg";
import Button from "elements/Button";
import { Typography } from "@mui/material";

const CreditInfo = () => {
  return (
    <SectionWrapper>
      <Container>
        <HeadingTwo
          variant="h2"
        >
          By Canadians, For Canadians
        </HeadingTwo>
        <QuotesHolder>
          <QuoteCol>
            <QuoteBox>
              <div className="heading">
                <Typography variant="h4">Joseph Apps</Typography>
                <span className="designation">CEO of Joe Apps Technology Support Inc.</span>
              </div>
              <Typography variant="h5">David and the team at SR&ED Plus Nexus have been instrumental in helping us navigate the complexities of the SR&ED program.</Typography>
              <Typography variant="body1">"Since partnering with them, we've been able to streamline our claims process significantly. David's thorough understanding of the SR&ED requirements and his ability to identify eligible activities we hadn't considered has been invaluable. His proactive approach and attention to detail have ensured our 
claims are always well-prepared, saving us considerable time and effort. I would highly recommend their services to any business looking to maximize their R&D funding opportunities."</Typography>
            </QuoteBox>
          </QuoteCol>
          <QuoteCol>
            <QuoteBox>
              <div className="heading">
                <Typography variant="h4">Nolan Anderson</Typography>
                <span className="designation">CTO of Auxe Inc.</span>
              </div>
              <Typography variant="h5">David has been extremely easy to work with for what would be a terribly hard process if we tried to do it in house. He has helped us receive an extremely necessary amount of SR&ED funding.</Typography>
              <Typography variant="body1">"We showed up in the middle of the year and he was able to catch us up and lay the groundwork on how to best track things for year end. We had initial meetings to help us understand what would fall under SR&ED which helped us lay out some 
internal projects to optimize our funding. After going through some of our business projects and processes, David was able to group certain activities together for us to easily track which team members put hours to each initiative. If you are someone looking to receive funding for SR&ED, I 
would recommend having a meeting with David. Within an hour you will fully understand the process and know you are in good hands."</Typography>
            </QuoteBox>
          </QuoteCol>
          <QuoteCol>
          <QuoteBox>
            <div className="heading">
              <Typography variant="h4">Robert C.</Typography>
              <span className="designation">CFO of RobotiX Automation</span>
            </div>
            <Typography variant="h5">SRED Capital saved us from audits</Typography>
            <Typography variant="body1">“We used to live in constant fear of audits. SRED Capital's meticulous approach not only maximized our claim but gave us confidence in its accuracy. When we did face an audit, they guided us through it seamlessly."</Typography>
          </QuoteBox>
          </QuoteCol>
        </QuotesHolder>
        <BtnWrapper>
          <Button color="primary" onClick={() => window.location.href = REQUEST_BUTTON_URL}>Meet With David</Button>
        </BtnWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default CreditInfo;
