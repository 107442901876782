import { styled } from '@mui/material';

export const ComparisonHolder = styled('div')`
    overflow: hidden;
`;


export const ComparisonHeader = styled('div')`
    overflow: hidden;
    margin: 0 auto 35px;
    color: white;
    font-family: 'Playfair Display';
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    font-weight: 900;
    max-width: 900px;

    @media (max-width: 1023px) {
        font-size: 24px;
        line-height: 30px;
    }

    span {
        display: block;
        color: #E3C050;
    }
`;

export const Container = styled('div')`
    max-width: 1080px;
    margin: 0 auto 99px;
    border-bottom:1px solid #2B2B2B;

    @media (max-width: 1023px) {
        max-width: 605px;
        margin: 0 auto 69px;
    }

    @media (max-width: 767px) {
        max-width: 375px;
        margin: 0 auto 60px;
    }
`;

export const ComparisonDetail = styled('div')`
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin: 0 0 100px;

   .col {
    position: relative;
    width: 50%;
    display: flex;
    padding: 0 5px;
    align-items: center;
    justify-content: center;

    &:first-child {
        &:after {
        position: absolute;
        right: -1px;
        top: 15%;
        bottom: 15%;
        width: 1px;
        background: #E3C050;
        content: "";
      }
      &:before {
        position: absolute;
        right: -14px;
        top: 50%;
        background: #131313;
        content: "VS";
        z-index: 6;
        padding: 5px 0;
        transform: translateY(-50%);
      } 
    }
   }

   @media (max-width: 1023px) {
    flex-direction: column;
    margin: 0 0 69px;

    .col {
        width: 100%;
        max-width: 345px;
        margin: 0 auto;

        &:first-child {
            margin: 0 auto 110px;
        &:after {
            position: absolute;
            right: 0;
            left: 0;
            top: auto;
            bottom: -55px;
            width: 100%;
            height: 1px;
            background: #E3C050;
            content: "";
        }
        &:before {
            position: absolute;
            right: 50%;
            top: auto;
            bottom: -73px;
            background: #131313;
            content: "VS";
            z-index: 6;
            padding: 5px;
            transform: translate(50%, 0);
        } 
        }
    }
   }
   @media (max-width: 1023px) {
    margin: 0 0 60px;
   }
   
`;
