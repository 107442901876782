import { styled } from '@mui/material';
import AppBar from "@mui/material/AppBar";

export const AppBarHolder = styled(AppBar)`
    z-index: 99;
    background: transparent;
    box-shadow: none;
    padding: 35px 46px;

    button {
        letter-spacing: 0.01em;

        @media (max-width: 767px) {
            transform: scale(1.2);
            width: 80%;
        }
    }

     @media (max-width: 767px) {
        padding: 35px 8px;
        position: absolute !important;

        img {
            width: 130px;
        }

        .button-holder {
            position: fixed;
            right: 0;
            left: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 10px 20px;
            background: linear-gradient(180deg, rgba(19, 19, 19, 0) 0%, rgba(19, 19, 19, 0.75) 26.14%, #131313 100%);
        }
     }
`;
