import { styled } from '@mui/material';

export const SliderHolder = styled('div')`
    overflow: hidden;
`;

export const Container = styled('div')`
    max-width: 1080px;
    margin: 0 auto 90px;
    padding: 0 0 70px;
    display: flex;
    flex-wrap: wrap;
    border-bottom:1px solid #2B2B2B;

    @media (max-width: 1023px) {
        flex-direction: column;
        margin: 0 auto 70px;
        padding: 0 0 45px;
        max-width: 700px;
    }

    @media (max-width: 767px) {
        margin: 0 auto 45px;
        padding: 0 0 45px;
        max-width: 360px;
    }
`;

export const Column = styled('div')`
    overflow: hidden;
    background: #1f1f1f;
    margin: 0 12px 25px;
    flex: 1;
    padding: 45px 24px;
    text-align: center;

    @media (max-width: 1023px) {
        width: 100%;
        max-width: 605px;
        margin: 0 auto 45px;
    }

    @media (max-width: 767px) {
        max-width: 360px;
        margin: 0 auto 24px;
    }

    img {
        display: block;
        margin: 0 auto 25px;
    }

    h2 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 900;
        text-align: center;
        margin: 0 auto;

        span {
            display: block;
            color: #E3C050;
        }
    }
`;

