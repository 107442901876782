import { REQUEST_BUTTON_URL } from "config";
import React from "react";
import { Container, TopbarWrapper } from "./style";

const Topbar = () => {
  return (
    <TopbarWrapper>
      <Container className="align-center">
        Want to compare your current SRED Capital against how much you could get with SRED Capital?
        <span className="breakline"><a href={`${REQUEST_BUTTON_URL}`} className="link">Request a CONSULTATION</a></span>
      </Container>
    </TopbarWrapper>
  );
};

export default Topbar;
