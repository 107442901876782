import React from "react";
import {
  FooterContentHolder,
  Container,
  LogoHolder,
  Box,
} from "components/FooterContent/style";
import { Typography } from "@mui/material";
import Logo from "assets/logo.svg";
import Chip from "elements/Chip";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import DoneIcon from "@mui/icons-material/Done";

const FooterContent = () => {
  return (
    <FooterContentHolder>
      <Container>
        <Typography variant="h2">
          Fund your innovation strategy & strengthen the Canadian economy
        </Typography>
        <Typography variant="body2" className="sub-head">
          An engagement with SRED Capital is simple and transparent: We get paid
          when you do. $0 upfront plus a 25% commission on the SRED Capital claim.
        </Typography>
        <Box>
          <div className="box-holder">
            <div className="chips">
              <Chip label="SRED Capital" className="lg" />
              <Chip
                label="NON-DILUTIVE"
                className="success"
                icon={<LoyaltyIcon />}
              />
            </div>
            <Typography variant="body2" className="head">
              Access more capital with less risk
            </Typography>
            <div className="box-head">
              <Typography variant="h3">$0 upfront</Typography>
              <div className="sub-heading">
                25% commission on the SRED Capital claim
              </div>
            </div>
            <ul className="checks">
              <li>
                <DoneIcon /> Aligned incentives
              </li>
              <li>
                <DoneIcon /> Access to capital
              </li>
              <li>
                <DoneIcon /> Expert advice
              </li>
              <li>
                <DoneIcon /> Effective process
              </li>
            </ul>
          </div>
        </Box>
        <LogoHolder>
          <img src={Logo} alt="SRED Capital" />
        </LogoHolder>
      </Container>
    </FooterContentHolder>
  );
};

export default FooterContent;
