import { styled } from '@mui/material';

export const HowItWorksHolder = styled('div')`
    overflow: hidden;
`;

export const Container = styled('div')`
    max-width: 1080px;
    margin: 0 auto 90px;
    border-bottom:1px solid #2B2B2B;
    padding: 0 0 80px;

    @media (max-width: 1023px) {
        max-width: 605px;
        padding: 0 0 70px;
        margin: 0 auto 70px;
    }

    @media (max-width: 767px) {
        max-width: 345px;
        padding: 0 0 60px;
        margin: 0 auto 60px;
    }

    h2 {
        font-size: 36px;
        line-height: 40px;
        font-weight: 900;
        text-align: center;
        margin: 0 auto 60px;

        span {
            display: block;
            color: #E3C050;
        }

        @media (max-width: 1023px) {
            font-size: 24px;
            line-height: 30px;
            margin: 0 auto 40px;
        }
    }

    img {
        display: block;
        margin: 0 auto 20px;

        @media (max-width: 1023px) {
            max-width: 345px;
        }
    }
`;