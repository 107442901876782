import React from "react";
import {
  ComparisonHolder,
  ComparisonHeader,
  Container,
  ComparisonDetail,
} from "components/Comparison/style";
import NewWay from "assets/new-way.svg";
import OldWay from "assets/old-way.svg";

const Comparison = () => {
  return (
    <ComparisonHolder>
      <Container>
        <ComparisonHeader>
          As a result, Canadian entrepreneurs are starved for innovation
          capital.
          <span>
            SRED Capital is your reliable partner for maximizing funding and tax
            credits.
          </span>
        </ComparisonHeader>
        <ComparisonDetail>
          <div className="col">
            <img src={OldWay} alt="Old Way" />
          </div>
          <div className="col">
            <img src={NewWay} alt="New Way" />
          </div>
        </ComparisonDetail>
      </Container>
    </ComparisonHolder>
  );
};

export default Comparison;
