import { styled } from '@mui/material';

export const BannerHolder = styled('div')`
    overflow: hidden;
    width: 100%;
    height: 730px;
    min-height: 500px;
    position: relative;

    @media (max-width: 1023px) {
        height: 510px;
    }
    @media (max-width: 767px) {
        height: 572px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 100%;

    }
`;

export const Caption = styled('div')`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0;

    .holder {
        max-width: 640px;
        margin: 0 auto;
        text-align: center;

        h1 {
            padding: 0 0 24px;
            margin: 0 auto 24px;
            border-bottom: 4px solid #E3C050;
            font-weight: 900;
            max-width: 640px;
        }

        p {
            color: #c0c0c0;
            font-size: 24px;
            line-height: 30px;
        }
    }

    @media (max-width: 1023px) {
        justify-content: flex-start;

        .holder {
          text-align: left;
          max-width: 370px;
          margin: 0 70px;

          h1 {
            font-size: 44px;
            max-width: 370px;
          }

          p {
            font-size: 18px;
            line-height: 24px;
          }
        }
    }

    @media (max-width: 767px) {
        .holder {
          margin: 0 24px;

        }
    }

    @media (max-width: 390px) {
        .holder {
            max-width: 320px;
        }
    }
`;