import React from "react";
import { BannerHolder, Caption } from "components/Banner/style";
import BannerImage from "assets/banner.jpg";
import Typography from "@mui/material/Typography";

const Banner = () => {
  return (
    <BannerHolder>
      <img src={BannerImage} alt="banner image" />
      <Caption>
        <div className="holder">
          <Typography variant="h1">
            Innovate. <br />
            Faster.
          </Typography>
          <p>
            SRED Capital is how the best Canadian entrepreneurs get near-instant
            access to non-dilutive capital with less stress and more success.
          </p>
        </div>
      </Caption>
    </BannerHolder>
  );
};

export default Banner;
