import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Button from "elements/Button";
import Logo from "assets/logo.svg";
import { useScrollTrigger } from "@mui/material";
import { AppBarHolder } from "./style";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 200,
  });

  return (
    <AppBarHolder
      position="fixed"
      elevation={trigger ? 60 : 0}
      sx={{
        background: trigger
          ? "linear-gradient(360deg, rgba(19, 19, 19, 0) 0%, rgba(19, 19, 19, 0.35) 14.65%, #131313 100%);"
          : "transparent",
      }}
      enableColorOnDark={true}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img src={Logo} alt="SRED Capital" />
        <div className="button-holder">
          <Button boxShadow="none" onClick={() => navigate("/register")}>
            REQUEST A CONSULTATION
          </Button>
        </div>
      </Toolbar>
    </AppBarHolder>
  );
};

export default Header;
